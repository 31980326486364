import React from 'react'
import 'lazysizes'
import { IntersectionObserverProvider } from "./src/provider/IntersectionObserverProvider"
const resetPage= () =>{
  const targetList = document.querySelectorAll('a')
  targetList.forEach(function (target) {
    if(target.target !== "_blank"){
      target.addEventListener('click', ()=>{
        if(document && document.querySelector("main")){
          document.querySelector("main").classList.add("loading")
          document.querySelector(".m_head").ariaExpanded = false;
          document.querySelector(".m_head_top").ariaExpanded = false;
          document.querySelector(".m_head_menu_outer").ariaExpanded = false;
        }
      });
    }
  });
}
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window.FONTPLUS !== "undefined"){
    window.FONTPLUS.reload()
  }
  document.querySelector("main").classList.remove("loading")
  document.querySelector("header").classList.remove("stay")
  window.previousPath = prevLocation ? prevLocation.pathname : null;
  resetPage()
  const mainImage = document.querySelector(".mainImage");

  if (mainImage) {
    if (mainImage.complete) {
      forceLoadLazyImages();
    } else {
      mainImage.addEventListener("load", forceLoadLazyImages, { once: true });
    }
  } else {
    forceLoadLazyImages();
  }
}

export const wrapRootElement = ({ element }) => (
  <IntersectionObserverProvider>{element}</IntersectionObserverProvider>
)

export const onInitialClientRender = () => {
  const mainImage = document.querySelector(".mainImage");
  console.log(mainImage)
  if (mainImage) {
    if (mainImage.complete) {
      forceLoadLazyImages();
    } else {
      mainImage.addEventListener("load", forceLoadLazyImages, { once: true });
    }
  } else {
    if (document.readyState === "complete") {
      forceLoadLazyImages();
    } else {
      window.addEventListener("load", forceLoadLazyImages);
    }
  }
};

const forceLoadLazyImages = () => {
  const images = document.querySelectorAll('img');
  images.forEach((img) => {
    if(img.loading == "lazy"){
      img.loading = "eager"; 
    }
    // 画像の読み込み完了時に 'loaded' クラスを追加
    const onLoad = () => {
      setTimeout(() => {
        img.classList.add("loaded");
      }
      , 500);
      img.removeEventListener("load", onLoad);
    };

    img.addEventListener("load", onLoad);

    // すでにキャッシュされている画像は即時クラスを付与
    if (img.complete) {
      setTimeout(() => {
        img.classList.add("loaded");
      }
      , 500);
    }
  });
};